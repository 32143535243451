@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('~src/assets/fonts/Barlow Semi Condensed/BarlowSemiCondensed-SemiBold.woff2') format('woff2'),
        url('~src/assets/fonts/Barlow Semi Condensed/BarlowSemiCondensed-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('~src/assets/fonts/Barlow Semi Condensed/BarlowSemiCondensed-Bold.woff2') format('woff2'),
        url('~src/assets/fonts/Barlow Semi Condensed/BarlowSemiCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

