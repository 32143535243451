/* ## Colors */

/* ### Primary */

$scissorsGradient: radial-gradient(circle, hsl(39, 89%, 49%) 0%, hsl(40, 84%, 53%) 100%);
$paperGradient: radial-gradient(circle, hsl(230, 89%, 62%) 0%, hsl(230, 89%, 65%) 100%);
$rockGradient: radial-gradient(circle, hsl(349, 71%, 52%) 0%, hsl(349, 70%, 56%) 100%);
$lizardGradient: radial-gradient(circle, hsl(261, 73%, 60%) 0%, hsl(261, 72%, 63%) 100%);
$spockGradient: radial-gradient(circle, hsl(189, 59%, 53%) 0%, hsl(189, 58%, 57%) 100%);

/* ### Neutral */

$darkText: hsl(229, 25%, 31%);
$scoreText: hsl(229, 64%, 46%);
$headerOutline: hsl(217, 16%, 45%);

/* ### Background */

$radialGradient: radial-gradient(circle, rgba(31,55,86,1) 0%, rgba(20,21,57,1) 100%);
